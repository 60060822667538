import { ThemeD } from "@/views/mokkupBuilder/components/modules/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LayoutItemD } from "../mokkupBuilder/mokkupBuilderTypes";
import { CustomisationsD } from "../mokkupBuilder/mokkupBuilderTypes";

export type UnsavedChangesD = {
  dashboardId: number;
  screenId: number;
  modifiedAt: string;
  sourceCode: LayoutItemD[];
  theme: ThemeD;
  customisations: CustomisationsD;
  canvasStyleCustomisation: {};
  elementStyleCustomisation: {};
};

const initialState: { unsavedChanges: UnsavedChangesD[] } = {
  unsavedChanges: [],
};

const unsavedChangesSlice = createSlice({
  name: "unsavedChanges",
  initialState,
  reducers: {
    updateUnsavedChanges: (state, action: PayloadAction<UnsavedChangesD>) => {
      const existingChange = state.unsavedChanges.find(
        (change) => change.dashboardId === action.payload.dashboardId
      );
      if (existingChange) {
        Object.assign(existingChange, action.payload);
      } else {
        state.unsavedChanges.push(action.payload);
      }
    },
    removeUnsavedChanges: (state, action: PayloadAction<number>) => {
      state.unsavedChanges = state.unsavedChanges.filter(
        (change) => change.dashboardId !== action.payload
      );
    },
    clearUnsavedChanges: (state) => {
      state.unsavedChanges = [];
    },
  },
});

export const {
  updateUnsavedChanges,
  removeUnsavedChanges,
  clearUnsavedChanges,
} = unsavedChangesSlice.actions;

export default unsavedChangesSlice.reducer;
