import React, { Component } from 'react';
import { connect } from 'react-redux';
import { localStorageKeys } from './constants/localStorageKeys';
import { routesConstants } from './constants/routes';
import { caching } from './helperFunctions/caching';


export default function (ComposedComponent) {
    class Authentication extends Component {
        componentDidMount() {
            caching()
            var authToken = localStorage.getItem(localStorageKeys.ACCESS_TOKEN)
            if (!authToken) {
                if ((window?.location?.href)?.includes("shared")) {
                    const arr = window?.location.href.split("/shared")
                    if(arr && (arr[1]!= 'undefined' && arr[1] !== '')){
                        localStorage.setItem(localStorageKeys.OPENPROJECTLINK, "/shared" + arr[1])
                    } else {
                        localStorage.removeItem(localStorageKeys.OPENPROJECTLINK)
                    }
                } else if ((window?.location?.href)?.includes("team")) {
                    const arr = window?.location.href.split("/team")
                    if(arr && (arr[1]!= 'undefined' && arr[1] !== '')){
                        localStorage.setItem(localStorageKeys.JOIN_TEAM_INVITE, "/team" + arr[1])
                    } else {
                        localStorage.removeItem(localStorageKeys.JOIN_TEAM_INVITE)
                    }
                }
                this.props.history.push(routesConstants.HOME);
            }
        }
        render() {
            return <ComposedComponent {...this.props} />
        }
    }

    function mapStateToProps(state) {
        return {
            ...state
        };
    }

    return connect(mapStateToProps)(Authentication);

}