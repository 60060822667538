import localforage from "localforage";

// redux-persist-code
localforage.config({
  driver: localforage.INDEXEDDB,
  name: "mokkupApp",
  storeName: "reduxPersist",
  description: "Persisted Redux store using IndexedDB",
});

const rootPersistConfig = {
  key: "unsavedChanges",
  storage: localforage,
  whitelist: ["unsavedChanges"],
};

export default rootPersistConfig;
